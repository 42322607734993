<template>
  <div>
    <CRow>
      <CCol sm="12">
        <Toolbars @set-action-toolbars="toolbars = $event"></Toolbars>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="4" v-if="showFilter">
        <Filtrate @filter-domain="filter = $event"></Filtrate>
      </CCol>
      <CCol :sm="getCol">
        <!-- GRID -->
        <Grid v-if="showGrid" :fields="fields" :items="items" @grid-row-selected="gridRowSelected = $event" @grid-dbclick="gridDbclick = $event"/>

        <!-- DETALHE -->
        <Detail v-else-if="!showGrid" :tracer="record">
          <template slot="default">
            <CRow>
              <CCol sm="4">
                <CInput id="domain_id" readonly v-model="record.domain_id">
                  <template #prepend-content
                    ><i class="fas fa-info-circle"
                  /></template>
                </CInput>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="12">
                <CInput
                  id="domain_name"
                  label="Domínio"
                  :readonly="readOnly"
                  v-model="record.domain_name"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="4">
                <CSelect
                  id="product_id"
                  label="Produto"
                  :readonly="readOnly"
                  :options="productOptions"
                  v-model="record.product_id"
                  @update:value="record.product_id = $event"
                />
              </CCol>
              <CCol sm="4">
                <CSelect
                  id="status_dm"
                  label="Situação"
                  :readonly="readOnly"
                  :options="statusOptions"
                  v-model="record.status_dm"
                  @update:value="record.status_dm = $event"
                />
              </CCol>
              <CCol sm="4">
                <CSelect
                  id="allow_change_dm"
                  label="Permite Alteração"
                  :readonly="readOnly"
                  :options="changeOptions"
                  v-model="record.allow_change_dm"
                  @update:value="record.allow_change_dm = $event"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="12">
                <CTextarea
                  id="domain_description"
                  :readonly="readOnly"
                  label="Descrição"
                  rows="2"
                  v-model="record.domain_description"
                />
              </CCol>
            </CRow>
          </template>
        </Detail>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import fn from "@/js"

import Toolbars from "@/components/Toolbars";
import Grid from "@/components/FormGrid";
import Detail from "@/components/FormDetail";
import Filtrate from "./DomainFilter";

export default {
  name: "Domain",
  props: {},
  components: { Toolbars, Grid, Detail, Filtrate },
  computed: {
    showFilter() {
      return this.$store.state.showFilter;
    },
    switchesIcon() {
      return this.$store.state.switchesIcon;
    },
    getCol() {
      return this.showFilter === false ? 12 : 8;
    }
  },
  data() {
    return {
      //Variaveis Fixas
      COMPONENT: {
        endPoint: "api/v01/internal/domain",
        primaryKey: "domain_id",
        descriptionKey: "domain_name",
        config: {
          child: false,
          delete: { method: "delete", atribute: null, label: null },
          allowCrud: false,
          fields: 'default',
        },
        finds: [],
      },
      //Variaveis Mutantes
      showGrid: true,
      readOnly: false,
      toolbars: {},
      filter: {},
      record: {},
      gridRowSelected: {},
      gridDbclick: {},
      fields: [],
      items: [],
      //Variaveis Componente <Select>
      productOptions: [],
      statusOptions: [],
      changeOptions: [],
    }
  },
  methods: {
    async init(){
      await this.$nextTick(() => {
        fn.initializeComponent(this)
        this.productOptions = fn.finds.findProducts();
        this.statusOptions = fn.finds.findValuesDomain(1);
        this.changeOptions = fn.finds.findValuesDomain(2);
      })
      this.afterInit()
    },
    afterInit() {
      fn.getRecords(this)
    },
    setInitialValues() {
      this.record.status_dm = 1
      this.record.allow_change_dm = 4
      fn.setAction(this)
    },
    setTypeAccess() {
      this.COMPONENT.typeAccess = this.userAuth.owner === true ? 'init':'view'
    },
  },
  watch: {
    toolbars: {
      immediate: true,
      handler(toolbars) {
        if(toolbars !== undefined && toolbars !== null && toolbars === 'add') {
          this.setInitialValues()
        } else if(toolbars !== undefined && toolbars !== null) {
          fn.setAction(this)
        }
      }
    },
    filter: {
      immediate: true,
      handler(filter) {
        if (Object.entries(filter).length !== 0) { 
          fn.getRecordsByFilters(this)    
        }
      }
    },
    gridRowSelected: {
      immediate: true,
      handler(gridRowSelected) {
        if (Object.entries(gridRowSelected).length !== 0) {           
            fn.loadRecord(this)          
        }
      }
    },
    gridDbclick: {
      immediate: true,
      handler(gridRowActionSelected) {
        if (Object.entries(gridRowActionSelected).length !== 0) {
          this.items = []
          let obj = {}
          obj.descriptionKey = `${this.gridDbclick[`${this.COMPONENT.primaryKey}`]} - ${this.gridDbclick[`${this.COMPONENT.descriptionKey}`]}`
          obj.parentName = this.$options.name
          obj.parentKey = this.COMPONENT.primaryKey
          obj.parentValue = this.gridDbclick[`${this.COMPONENT.primaryKey}`]
          this.$emit("set-hierarchy-level", obj);
        }
      }
    },
  },
  created() {
    this.init()
  },
};
</script>
